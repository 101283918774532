import Chapter from '@models/Chapter';

class ChapterFactory {
    public create(node): Chapter {
        const { frontmatter, body, fields } = node;

        const year = frontmatter.date.split('/')[0];
        const month = frontmatter.date.split('/')[1];
        const day = frontmatter.date.split('/')[2];

        return new Chapter({
            index: frontmatter.index,
            date: new Date(year, month - 1, day),
            length: frontmatter.length,
            title: frontmatter.title,
            description: frontmatter.description,
            body: body,
            path: fields.path,
        });
    }

    public batchCreate(nodes): Chapter[] {
        return nodes.map(({ node }) => this.create(node));
    }
}

export default new ChapterFactory();
