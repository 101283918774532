/* eslint-disable react/no-unescaped-entities */
// Modules
import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';

// Factories
import ChapterFactory from '@factories/ChapterFactory';

// Components
import { Layout } from '@components/core/layout';
import { Inner } from '@components/core/inner';
import { Typo } from '@components/core/typo';
import { Outer } from '@components/core/outer';

import css from '@emotion/css';
import { colors, fontSize, spacing, screens, borderRadius, boxShadow, fontWeight } from '@styles/theme';
import { Header } from '@components/core/header';
import { Card } from '@components/core/card';
import { Grid } from '@components/core/grid';

import { Content } from '@components/workshop/content';
import { Player } from '@components/core/player/player';
import Chapter from '@models/Chapter';
import { Footer } from '@components/core/footer';
import { NavigationCardBody } from '@components/chapter/navigation-card-body';
import { Metadata } from '@components/core/metadata';
import { WindowLocation } from '@reach/router';
import { Icon } from '@components/core/icon';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useDebounce } from 'use-debounce';
import { ToastContainer, Slide } from 'react-toastify';

interface ChapterTemplateProps {
    data: any;
    location: WindowLocation;
}

const ChapterTemplate: React.FC<ChapterTemplateProps> = ({ data, location }) => {
    const node = data.mdx;
    const chapter = ChapterFactory.create(node);
    const workshopChapters = ChapterFactory.batchCreate(data.allMdx.edges);
    const previousChapter = workshopChapters.find(workshopChapter => workshopChapter.index === chapter.index - 1);
    const nextChapter = workshopChapters.find(workshopChapter => workshopChapter.index === chapter.index + 1);

    const { title, body } = chapter;
    if (typeof window === 'undefined') {
        return null;
    }

    const [styles, setStyles] = useState(`transform: translateY(-${spacing(32)});`);
    const [progressPercentage, setProgressPercentage] = useState(0);

    useScrollPosition(({ prevPos, currPos }) => {
        if (currPos.y < -120) {
            setStyles(`transform: translateY(0);`);
        }
        if (currPos.y === 0) {
            setStyles(`transform: translateY(-${spacing(32)});`);
        }

        function getScrollPercent(): number {
            const { documentElement, body } = document;
            const {
                scrollTop: documentElementScrollTop,
                scrollHeight: documentElementScrollHeight,
                clientHeight,
            } = documentElement;
            const { scrollTop: bodyScrollTop, scrollHeight: bodyScrollHeight } = body;
            const percent =
                ((documentElementScrollTop || bodyScrollTop) /
                    ((documentElementScrollHeight || bodyScrollHeight) - clientHeight)) *
                100;
            return Math.ceil(percent);
        }

        setProgressPercentage(getScrollPercent());
    });

    const currentAndDoneChaptersIndex = workshopChapters
        .map(value => {
            if (value.index < chapter.index) {
                return value.index;
            }

            if (value.index === chapter.index) {
                return value.index;
            }
            return false;
        })
        .filter(value => value);

    return (
        <Layout>
            <Metadata pathname={location.pathname} ressource={chapter} />
            <ToastContainer
                css={css`
                    > div {
                        padding: ${spacing(3)};

                        border-left: ${spacing(1)} solid ${colors('green', 400)};
                        border-radius: ${borderRadius('md')};
                        box-shadow: ${boxShadow('md')};
                    }
                `}
                position="bottom-right"
                transition={Slide}
                hideProgressBar={true}
                closeButton={false}
                autoClose={2500}
            />
            <Outer>
                <Header>
                    <Inner>
                        <Link
                            to="/websockets-whatsapp-clone"
                            css={css`
                                :hover label {
                                    color: ${colors('secondary')};
                                }

                                svg {
                                    transform: translate3d(0, 0, 0);

                                    transition: all 0.133s ease-out;
                                }

                                :hover svg {
                                    color: ${colors('secondary')};

                                    transform: translate3d(-20px, 0, 0);
                                }
                            `}
                        >
                            <div
                                css={css`
                                    display: inline-flex;
                                    align-items: center;
                                `}
                            >
                                <Icon
                                    id="arrow-left"
                                    css={css`
                                        margin-right: ${spacing(6)};

                                        color: ${colors('gray', 600)};
                                    `}
                                />
                                <div>
                                    <Typo
                                        variant="label"
                                        css={css`
                                            color: ${colors('gray', 600)};
                                            cursor: pointer;

                                            transition: all 0.1333s ease-out;
                                        `}
                                    >
                                        Retour au
                                    </Typo>
                                    <Typo
                                        variant="h2"
                                        css={css`
                                            margin-top: ${spacing(1)};

                                            color: ${colors('secondary')};
                                        `}
                                    >
                                        Sommaire
                                    </Typo>
                                </div>
                            </div>
                        </Link>
                    </Inner>
                </Header>
            </Outer>
            <Inner
                css={css`
                    margin-bottom: 0;
                `}
            >
                <Grid
                    sm={{ columns: 1, columnGap: 0, rowGap: 0 }}
                    md={{ columns: 1, columnGap: 0, rowGap: 0 }}
                    lg={{ columns: 3, columnGap: 16, rowGap: 0 }}
                    css={css`
                        height: auto !important;
                        max-height: auto !important;
                        overflow: visible;
                    `}
                >
                    <Content className="mdx-content" title={title} body={body} />
                    <div
                        css={css`
                            position: relative;
                            ${styles}
                            transition: transform ease-in-out 300ms 100ms;

                            grid-area: 1 / 3 / 2 / 4;

                            @media (max-width: ${screens('lg')}) {
                                display: none;
                            }
                        `}
                    >
                        <Player
                            css={css`
                                position: sticky;
                                top: ${spacing(4)};
                                height: auto !important;
                                max-height: none !important;
                                overflow: visible;
                            `}
                        >
                            <div
                                css={css`
                                    height: auto !important;
                                    max-height: auto !important;

                                    padding: ${spacing(6)};
                                    overflow: visible;

                                    color: ${colors('gray', 500)};

                                    > a > h3 {
                                        padding: ${spacing(3)} 0;
                                    }

                                    > a:first-of-type > h3 {
                                        padding-top: 0;
                                    }

                                    > a:last-of-type > h3 {
                                        padding-bottom: 0;
                                    }

                                    .active {
                                        color: ${colors('secondary')};
                                    }
                                `}
                            >
                                {workshopChapters.map((value: Chapter) => {
                                    return (
                                        <Link key={value.path} to={value.path} activeClassName="active">
                                            <div
                                                css={css`
                                                    
                                                    margin-top: ${value.index !== 1 ? spacing(4) : 0}};


                                                    :hover {
                                                        color: ${colors('secondary')};
                                                        label {
                                                            color: ${colors('secondary')};
                                                        }
                                                    }
                                                `}
                                            >
                                                <div
                                                    css={css`
                                                        display: flex;
                                                        align-items: center;
                                                    `}
                                                >
                                                    <div
                                                        css={css`
                                                            height: ${spacing(8)};
                                                            width: ${spacing(8)};
                                                            display: flex;
                                                            justify-content: center;
                                                            align-items: center;
                                                            flex-shrink: 0;

                                                            border-radius: 50%;

                                                            border: 1px solid ${colors('gray', 300)};
                                                        `}
                                                    >
                                                        {value.index < chapter.index ? (
                                                            <Icon
                                                                id="check"
                                                                css={css`
                                                                    font-size: ${fontSize('xs')};
                                                                    height: ${spacing(8)};
                                                                    width: ${spacing(8)};
                                                                    color: ${currentAndDoneChaptersIndex.includes(
                                                                        value.index,
                                                                    )
                                                                        ? colors('secondary')
                                                                        : colors('gray', 400)};
                                                                `}
                                                            ></Icon>
                                                        ) : (
                                                            <Typo
                                                                css={css`
                                                                    cursor: pointer;
                                                                    font-size: ${fontSize('xs')};
                                                                    font-weight: ${fontWeight('bold')};
                                                                    color: ${currentAndDoneChaptersIndex.includes(
                                                                        value.index,
                                                                    )
                                                                        ? colors('secondary')
                                                                        : colors('gray', 400)};

                                                                    color: ${chapter.index === value.index
                                                                        ? colors('secondary')
                                                                        : colors('gray', 400)};
                                                                `}
                                                            >
                                                                {value.index}
                                                            </Typo>
                                                        )}
                                                    </div>
                                                    <Typo
                                                        css={css`
                                                            cursor: pointer;
                                                            margin-left: ${spacing(2)};
                                                            font-size: ${fontSize('sm')};
                                                            font-weight: ${fontWeight('medium')};
                                                            color: ${chapter.index === value.index
                                                                ? colors('secondary')
                                                                : colors('gray', 400)};
                                                        `}
                                                    >
                                                        {value.title}
                                                    </Typo>
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                })}
                            </div>
                        </Player>
                    </div>
                </Grid>
            </Inner>
            <Outer>
                <Footer>
                    <Inner>
                        <Grid
                            css={css`
                                position: relative;
                                transform: translate3d(0, -${spacing(24)}, 0);
                            `}
                            sm={{ columns: 2, columnGap: 4, rowGap: 0 }}
                            md={{ columns: 2, columnGap: 4, rowGap: 0 }}
                            lg={{ columns: 2, columnGap: 4, rowGap: 0 }}
                        >
                            <Link
                                to={previousChapter ? previousChapter.path : '/websockets-whatsapp-clone'}
                                css={css`
                                    label {
                                        color: ${colors('gray', 400)};

                                        transition: all 0.133s ease-out;
                                    }
                                    :hover {
                                        label {
                                            color: ${colors('secondary')};
                                        }
                                    }

                                    svg {
                                        transform: translate3d(20px, 0, 0);

                                        transition: all 0.133s ease-out;
                                    }

                                    :hover svg {
                                        color: ${colors('secondary')};

                                        transform: translate3d(0, 0, 0);
                                    }
                                `}
                            >
                                <Card
                                    css={css`
                                        padding: ${spacing(6)};

                                        text-align: right;

                                        @media (max-width: ${screens('md')}) {
                                            top: -${spacing(16)};
                                        }
                                    `}
                                >
                                    <div
                                        css={css`
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;

                                            > div {
                                                display: flex;
                                                flex-direction: column;
                                                align-items: flex-end;
                                            }
                                        `}
                                    >
                                        <Icon
                                            id="arrow-left"
                                            css={css`
                                                color: ${colors('gray', 600)};
                                            `}
                                        />
                                        <NavigationCardBody
                                            label={previousChapter ? 'Précedent' : 'Retour au'}
                                            title={previousChapter ? previousChapter.title : 'Sommaire'}
                                        />
                                    </div>
                                </Card>
                            </Link>
                            <Link
                                to={nextChapter ? nextChapter.path : '/websockets-whatsapp-clone'}
                                css={css`
                                    label {
                                        color: ${colors('gray', 400)};

                                        transition: all 0.133s ease-out;
                                    }
                                    :hover {
                                        label {
                                            color: ${colors('secondary')};
                                        }
                                    }

                                    svg {
                                        transform: translate3d(-20px, 0, 0);

                                        transition: all 0.133s ease-out;
                                    }

                                    :hover svg {
                                        color: ${colors('secondary')};

                                        transform: translate3d(0, 0, 0);
                                    }
                                `}
                            >
                                <Card
                                    css={css`
                                        padding: ${spacing(6)};

                                        text-align: left;

                                        @media (max-width: ${screens('md')}) {
                                            top: -${spacing(16)};
                                        }
                                    `}
                                >
                                    <div
                                        css={css`
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;

                                            > div {
                                                display: flex;
                                                flex-direction: column;
                                                align-items: flex-start;
                                            }
                                        `}
                                    >
                                        <NavigationCardBody
                                            label={nextChapter ? 'Suivant' : 'Retour au'}
                                            title={nextChapter ? nextChapter.title : 'Sommaire'}
                                        />
                                        <Icon
                                            id="arrow-right"
                                            css={css`
                                                color: ${colors('gray', 600)};
                                            `}
                                        />
                                    </div>
                                </Card>
                            </Link>
                        </Grid>
                    </Inner>
                </Footer>
            </Outer>
        </Layout>
    );
};

export default ChapterTemplate;

export const ChapterTemplateQuery = graphql`
    query AllChaptersAndCurrentChapter($id: String!) {
        allMdx(
            sort: { fields: frontmatter___index, order: ASC }
            filter: { fields: { contentType: { eq: "chapter" } } }
        ) {
            edges {
                node {
                    fields {
                        id
                        path
                    }
                    frontmatter {
                        index
                        title
                        description
                        date
                    }
                }
            }
        }
        mdx(fields: { id: { eq: $id } }) {
            fields {
                id
                path
            }
            body
            frontmatter {
                index
                title
                description
                date
            }
        }
    }
`;
