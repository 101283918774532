import React from 'react';
import { Link } from 'gatsby';
import { Typo } from '@components/core';
import css from '@emotion/css';
import { spacing, colors, screens, fontSize } from '@styles/theme';

interface NavigationCardBodyProps {
    label: string;
    title: string;
}

export const NavigationCardBody: React.FC<NavigationCardBodyProps> = ({ label, title }) => {
    return (
        <div
            css={css`
                @media (max-width: ${screens('lg')}) {
                    > h3 {
                        display: none;
                    }

                    label {
                        color: ${colors('secondary')}!important;
                        font-size: ${fontSize('md')};
                    }
                }
            `}
        >
            <Typo variant="label">{label}</Typo>
            <Typo
                variant="h3"
                css={css`
                    margin-top: ${spacing(4)};
                `}
            >
                {title}
            </Typo>
        </div>
    );
};
